body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

body.light-mode {
  background-color: #ffffff;
  color: #000000;
}

body.dark-mode .table {
  background-color: #1e1e1e;
  color: #ffffff;
}

body.dark-mode .table thead th {
  background-color: #333333;
  color: #ffffff;
}

body.dark-mode .table tbody tr {
  background-color: #1e1e1e;
  color: #ffffff;
}

body.dark-mode .table tbody tr:nth-of-type(odd) {
  background-color: #2a2a2a;
}

body.dark-mode .table tbody tr:hover {
  background-color: #444444;
}

/* Dark mode styles for modal components */
body.dark-mode .modal-content {
  background-color: #1e1e1e;
  color: #ffffff;
}

body.dark-mode .modal-header,
body.dark-mode .modal-footer {
  border-color: #444444;
}

body.dark-mode .modal-header .close {
  color: #ffffff;
}

body.dark-mode .modal-body {
  background-color: #1e1e1e;
  color: #ffffff;
}

body.dark-mode .btn-close {
  filter: invert(1);
}

html, body, #root {
  height: 100%;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-content {
  flex: 1;
  padding: 1rem;
  margin-top: 56px; /* Adjust for fixed header height */
  margin-bottom: 56px; /* Adjust for fixed footer height */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.search-form {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input {
  border-radius: 20px;
  padding: 0.5rem 1rem;
  border: 1px solid #ced4da;
  transition: all 0.3s ease-in-out;
  padding-right: 2.5rem; /* Add padding to the right for the icon */
}

.search-input:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.search-icon {
  position: absolute;
  right: 1rem;
  color: #ced4da;
  pointer-events: none;
}

ul.list-unstyled {
  padding-left: 0;
}

ul.list-unstyled ul {
  padding-left: 20px;
}

.card {
  margin-bottom: 1rem;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.card-text {
  margin-bottom: 0.5rem;
}

button.btn-link {
  margin-right: 0.5rem;
}

.project-group-card {
  position: relative;
  background-color: var(--card-bg);
  color: var(--card-text);
  border: 1px solid #7d7d7d;
  min-height: 200px;
}

.project-group-card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-group-card .card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.project-group-card .card-title a {
  color: inherit;
  text-decoration: none;
}

.project-group-card .card-title a:hover {
  text-decoration: underline;
}

.project-group-card .card-text {
  margin-bottom: 0.5rem;
}

.project-group-card .badge {
  position: absolute;
  top: 10px;
  right: 10px;
}

.project-group-card .btn-link {
  margin-right: 0.5rem;
  color: var(--icon-color);
}

.project-group-card .btn-link:hover {
  color: var(--icon-hover-color);
}

/* Dark mode styles */
body.dark-mode {
  --card-bg: #1e1e1e;
  --card-text: #ffffff;
  --icon-color: #ffffff;
  --icon-hover-color: #cccccc;
}

/* Light mode styles */
body.light-mode {
  --card-bg: #ffffff;
  --card-text: #000000;
  --icon-color: #000000;
  --icon-hover-color: #333333;
}
.project-card {
  position: relative;
  background-color: var(--card-bg);
  color: var(--card-text);
  min-height: 200px;;
  border: 1px solid #7d7d7d;
}

.project-card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-card .card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.project-card .card-title a {
  color: inherit;
  text-decoration: none;
}

.project-card .card-title a:hover {
  text-decoration: underline;
}

.project-card .card-text {
  margin-bottom: 0.5rem;
}

.project-card .badge-tasks {
  position: absolute;
  top: 10px;
  right: 10px;
}

.project-card .badge a {
  color: inherit;
  text-decoration: none;
}

.project-card .badge a:hover {
  text-decoration: underline;
}

.project-card .btn-link {
  margin-right: 0.5rem;
  color: var(--icon-color);
}

.project-card .btn-link:hover {
  color: var(--icon-hover-color);
}

.project-card .d-flex {
  display: flex;
  align-items: center;
}

.project-card .justify-content-between {
  justify-content: space-between;
}

.project-card .align-items-center {
  align-items: center;
}

/* Dark mode styles */
body.dark-mode {
  --card-bg: #1e1e1e;
  --card-text: #ffffff;
  --icon-color: #ffffff;
  --icon-hover-color: #cccccc;
}

/* Light mode styles */
body.light-mode {
  --card-bg: #ffffff;
  --card-text: #000000;
  --icon-color: #000000;
  --icon-hover-color: #333333;
}

/* Dark mode styles */
body.dark-mode {
  --card-bg: #1e1e1e;
  --card-text: #ffffff;
  --icon-color: #ffffff;
  --icon-hover-color: #cccccc;
}

/* Light mode styles */
body.light-mode {
  --card-bg: #ffffff;
  --card-text: #000000;
  --icon-color: #000000;
  --icon-hover-color: #333333;
}

/* Dark mode styles */
body.dark-mode {
  --card-bg: #1e1e1e;
  --card-text: #ffffff;
  --icon-color: #ffffff;
  --icon-hover-color: #cccccc;
}

/* Light mode styles */
body.light-mode {
  --card-bg: #ffffff;
  --card-text: #000000;
  --icon-color: #000000;
  --icon-hover-color: #333333;
}